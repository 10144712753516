import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { CrtFilter } from 'src/app/core/document-emitter/filters/CrtFilter';
import { MicFilter } from 'src/app/core/document-emitter/filters/MicFilter';
import { DashboardProcessFilter } from 'src/app/core/follow-up/filters/DashboardProcessFilter';
import * as FiltersAction from '../../store/filters.action';
import { IFiltersState } from '../../store/filters.state';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private store: Store<{
    filters: IFiltersState;
  }> = inject(
    Store<{
      filters: IFiltersState;
    }>
  );

  constructor() {}

  // ######### get, clean GERAL

  getStoreFilters(): Observable<IFiltersState> {
    return this.store.select('filters');
  }

  clearStoreAllFilters() {
    this.store.dispatch(FiltersAction.clearAll());
  }

  // ######### get ANINHADO DE AMBOS [filtro, filtro tabela, filtro avançado] (follow, crt, mic)

  getStoreFollow(): Observable<[DashboardProcessFilter | null, any, any]> {
    return combineLatest([
      this.store.select('filters', 'followFilter'),
      this.store.select('filters', 'followTableFilter'),
      this.store.select('filters', 'followAdvancedFilter'),
    ]);
  }

  getStoreCrt(): Observable<[CrtFilter | null, any, any]> {
    return combineLatest([
      this.store.select('filters', 'crtFilter'),
      this.store.select('filters', 'crtTableFilter'),
      this.store.select('filters', 'crtAdvancedFilter'),
    ]);
  }

  getStoreMic(): Observable<[MicFilter | null, any, any]> {
    return combineLatest([
      this.store.select('filters', 'micFilter'),
      this.store.select('filters', 'micTableFilter'),
      this.store.select('filters', 'micAdvancedFilter'),
    ]);
  }

  // ######### get, set, clean DO [filtro] (follow, crt, mic)

  getStoreFollowFilters(): Observable<DashboardProcessFilter | null> {
    return this.store.select('filters', 'followFilter');
  }

  getStoreCrtFilters(): Observable<CrtFilter | null> {
    return this.store.select('filters', 'crtFilter');
  }

  getStoreMicFilters(): Observable<MicFilter | null> {
    return this.store.select('filters', 'micFilter');
  }

  setStoreFollowFilters(filters: DashboardProcessFilter | null) {
    this.store.dispatch(FiltersAction.setFollowFilters({ filters }));
  }

  setStoreCrtFilters(filters: CrtFilter) {
    this.store.dispatch(FiltersAction.setCrtFilters({ filters }));
  }

  setStoreMicFilters(filters: MicFilter) {
    this.store.dispatch(FiltersAction.setMicFilters({ filters }));
  }

  clearStoreFollowFilters() {
    this.store.dispatch(FiltersAction.clearFollowFilters());
  }

  clearStoreCrtFilters() {
    this.store.dispatch(FiltersAction.clearCrtFilters());
  }

  clearStoreMicFilters() {
    this.store.dispatch(FiltersAction.clearMicFilters());
  }

  // ######### get, set, clean DO [filtro tabela] (follow, crt, mic)

  getStoreFollowTableFilters(): Observable<DashboardProcessFilter | null> {
    return this.store.select('filters', 'followTableFilter');
  }

  getStoreCrtTableFilters(): Observable<CrtFilter | null> {
    return this.store.select('filters', 'crtTableFilter');
  }

  getStoreMicTableFilters(): Observable<MicFilter | null> {
    return this.store.select('filters', 'micTableFilter');
  }

  setStoreFollowTableFilters(form: any) {
    this.store.dispatch(FiltersAction.setFollowTableFilters({ form }));
  }

  setStoreCrtTableFilters(form: any) {
    this.store.dispatch(FiltersAction.setCrtTableFilters({ form }));
  }

  setStoreMicTableFilters(form: any) {
    this.store.dispatch(FiltersAction.setMicTableFilters({ form }));
  }

  clearStoreFollowTableFilters() {
    this.store.dispatch(FiltersAction.clearFollowTableFilters());
  }

  clearStoreCrtTableFilters() {
    this.store.dispatch(FiltersAction.clearCrtTableFilters());
  }

  clearStoreMicTableFilters() {
    this.store.dispatch(FiltersAction.clearMicTableFilters());
  }

  // ######### get, set, clean DO [filtro avançado] (follow, crt, mic)

  getStoreFollowAdvancedFilters(): Observable<DashboardProcessFilter | null> {
    return this.store.select('filters', 'followAdvancedFilter');
  }

  getStoreCrtAdvancedFilters(): Observable<CrtFilter | null> {
    return this.store.select('filters', 'crtAdvancedFilter');
  }

  getStoreMicAdvancedFilters(): Observable<MicFilter | null> {
    return this.store.select('filters', 'micAdvancedFilter');
  }

  setStoreFollowAdvancedFilters(form: any) {
    this.store.dispatch(FiltersAction.setFollowAdvancedFilters({ form }));
  }

  setStoreCrtAdvancedFilters(form: any) {
    this.store.dispatch(FiltersAction.setCrtAdvancedFilters({ form }));
  }

  setStoreMicAdvancedFilters(form: any) {
    this.store.dispatch(FiltersAction.setMicAdvancedFilters({ form }));
  }

  clearStoreFollowAdvancedFilters() {
    this.store.dispatch(FiltersAction.clearFollowAdvancedFilters());
  }

  clearStoreCrtAdvancedFilters() {
    this.store.dispatch(FiltersAction.clearCrtAdvancedFilters());
  }

  clearStoreMicAdvancedFilters() {
    this.store.dispatch(FiltersAction.clearMicAdvancedFilters());
  }

  // ######### update DO [filtro] (follow, crt, mic)

  updateFollowFiltersPagination(
    size: number,
    page: number,
    sortField: string | string[],
    sortOrder: string | string[]
  ) {
    this.store.dispatch(
      FiltersAction.updateFollowFilterPagination({
        size,
        page,
        sortField,
        sortOrder,
      })
    );
  }

  updateCrtFiltersPagination(
    size: number,
    page: number,
    sortField: string | string[],
    sortOrder: string | string[]
  ) {
    this.store.dispatch(
      FiltersAction.updateCrtFilterPagination({
        size,
        page,
        sortField,
        sortOrder,
      })
    );
  }

  updateMicFiltersPagination(
    size: number,
    page: number,
    sortField: string | string[],
    sortOrder: string | string[]
  ) {
    this.store.dispatch(
      FiltersAction.updateMicFilterPagination({
        size,
        page,
        sortField,
        sortOrder,
      })
    );
  }
}
