import { CommonModule, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { ReadPermissionGuard } from './app/core/common/auth/read-permission.guard';
import { WritePermissionGuard } from './app/core/common/auth/write-permission.guard';
import { ChunkErrorHandler } from './app/core/common/handler/chunk-error-hanlder';
import { CustomMissingTranslationHandler } from './app/core/common/handler/custom-missing-translate-handler';
import {
  HttpLoaderFactory,
  initializeApp1,
} from './app/core/common/helpers/InitializeUtils';
import { AuthInterceptor } from './app/core/common/interceptors/authentication-requests.interceptor';
import { ErrorCatchingInterceptor } from './app/core/common/interceptors/error-catching.interceptor';
import { TokenErrorsInterceptor } from './app/core/common/interceptors/token-errors.interceptor';
import { AppInitService } from './app/core/common/services/app-init/app-init.service';
import { DialogInterceptorService } from './app/core/common/services/modal/dialog-interceptor.service';
import { profileCompanyReducer } from './app/core/common/store/company.state';
import { contractReducer } from './app/core/common/store/contract.state';
import { filtersReducer } from './app/core/common/store/filters.state';
import { menuReducer } from './app/core/common/store/menu.state';
import { tablePreferencesStateReducer } from './app/core/document-emitter/store/table-preferences.state';
import { ProfileFacade } from './app/pages/settings/general-settings/management-registers/my-profile/facade/ProfileFacade';
import { SharedModule } from './app/shared/shared.module';
import { environment } from './environments/environment';

if (environment.production) enableProdMode();

registerLocaleData(localePt);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      SharedModule,
      ReactiveFormsModule,
      BrowserModule,
      MessageModule,
      MessagesModule,
      ButtonModule,
      DialogModule,
      ScrollPanelModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: CustomMissingTranslationHandler,
        },
        defaultLanguage: 'pt',
      }),
      StoreModule.forRoot({
        menu: menuReducer,
        profileCompany: profileCompanyReducer,
        tablePreferences: tablePreferencesStateReducer,
        contract: contractReducer,
        filters: filtersReducer,
      }),
      StoreDevtoolsModule.instrument(),
      BackButtonDisableModule.forRoot()
    ),
    ConfirmationService,
    MessageService,
    DialogService,
    DialogInterceptorService,
    HttpClientModule,
    AppInitService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenErrorsInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp1,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: FilterDashboardFollowStoreInterceptor,
    //   multi: true,
    // },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: ErrorHandler, useClass: ChunkErrorHandler },
    ReadPermissionGuard,
    WritePermissionGuard,
    ProfileFacade,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(APP_ROUTES),
  ],
}).catch((error) => console.error(error));
