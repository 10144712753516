import { TableLazyLoadEvent } from 'primeng/table';

export class Pageable {
  public page: number = 0;
  public size: number = 10;
  public sortField: string | string[] = '';
  public sortOrder: string = '';
  public isFiltering = false;

  constructor(params?: Partial<Pageable>) {
    Object.assign(this, params);
  }

  aoMudarPagina(event: TableLazyLoadEvent): void {
    this.size = event?.rows!;
    this.page = event?.first! / event.rows!;
    this.sortField = event?.sortField!;
    this.sortOrder = event?.sortOrder?.toString() === '1' ? 'asc' : 'desc';
  }
}
