import { createAction, props } from '@ngrx/store';
import { CrtFilter } from '../../document-emitter/filters/CrtFilter';
import { MicFilter } from '../../document-emitter/filters/MicFilter';
import { DashboardProcessFilter } from '../../follow-up/filters/DashboardProcessFilter';

// ######### clear ALL

export const clearAll = createAction(
  '[Filters and Form Filters] Clear All Filters both'
);

// ######### clear ALL [filtro]

export const clearAllFilters = createAction('[Filters] Clear All Filters');

// ######### clear ALL [filtro tabela]

export const clearAllTableFilters = createAction(
  '[Table Filters] Clear All Table Filters'
);

// ######### clear ALL [filtro avançado]

export const clearAllAdvancedFilters = createAction(
  '[Advanced Filters] Clear All Advanced Filters'
);

// ######### set, clear [filtro] (follow, crt, mic)

export const setFollowFilters = createAction(
  '[Filters] Set Follow Filters',
  props<{ filters: DashboardProcessFilter | null }>()
);

export const setCrtFilters = createAction(
  '[Filters] Set CRT Filters',
  props<{ filters: CrtFilter | null }>()
);

export const setMicFilters = createAction(
  '[Filters] Set MIC Filters',
  props<{ filters: MicFilter | null }>()
);

export const clearFollowFilters = createAction(
  '[Filters] Clear Follow Filters'
);

export const clearCrtFilters = createAction('[Filters] Clear CRT Filters');

export const clearMicFilters = createAction('[Filters] Clear MIC Filters');

// ######### set, clear [filtro tabela] (follow, crt, mic)

export const setFollowTableFilters = createAction(
  '[Table Filters] Set Follow Table Filters',
  props<{ form: any }>()
);

export const setCrtTableFilters = createAction(
  '[Table Filters] Set CRT Table Filters',
  props<{ form: any }>()
);

export const setMicTableFilters = createAction(
  '[Table Filters] Set MIC Table Filters',
  props<{ form: any }>()
);

export const clearFollowTableFilters = createAction(
  '[Table Filters] Clear Follow Table Filters'
);

export const clearCrtTableFilters = createAction(
  '[Table Filters] Clear CRT Table Filters'
);

export const clearMicTableFilters = createAction(
  '[Table Filters] Clear MIC Table Filters'
);

// ######### set, clear [filtro avançado] (follow, crt, mic)

export const setFollowAdvancedFilters = createAction(
  '[Advanced Filters] Set Follow Advanced Filters',
  props<{ form: any }>()
);

export const setCrtAdvancedFilters = createAction(
  '[Advanced Filters] Set CRT Advanced Filters',
  props<{ form: any }>()
);

export const setMicAdvancedFilters = createAction(
  '[Advanced Filters] Set MIC Advanced Filters',
  props<{ form: any }>()
);

export const clearFollowAdvancedFilters = createAction(
  '[Advanced Filters] Clear Follow Advanced Filters'
);

export const clearCrtAdvancedFilters = createAction(
  '[Advanced Filters] Clear CRT Advanced Filters'
);

export const clearMicAdvancedFilters = createAction(
  '[Advanced Filters] Clear MIC Advanced Filters'
);

// ######### update [filtro] (follow, crt, mic)

export const updateFollowFilterPagination = createAction(
  '[Filters] Update Follow Filter Pagination',
  props<{
    size?: number;
    page?: number;
    sortField?: string | string[];
    sortOrder?: string | string[];
  }>()
);

export const updateCrtFilterPagination = createAction(
  '[Filters] Update CRT Filter Pagination',
  props<{
    size?: number;
    page?: number;
    sortField?: string | string[];
    sortOrder?: string | string[];
  }>()
);

export const updateMicFilterPagination = createAction(
  '[Filters] Update MIC Filter Pagination',
  props<{
    size?: number;
    page?: number;
    sortField?: string | string[];
    sortOrder?: string | string[];
  }>()
);
