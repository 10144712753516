import { inject, Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalComponentService } from './modal-component.service';

@Injectable({
  providedIn: 'root',
})
export class DialogInterceptorService {
  modalService = inject(ModalComponentService);
  dialogService = inject(DialogService);

  constructor() {}

  open(component: any, config?: any): DynamicDialogRef {
    const ref = this.dialogService.open(component, config);
    this.modalService.registerDynamicDialog(ref);
    return ref;
  }
}
