import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ItemMenuCode } from '../../enums/ItemMenuCode.enum';
import { MenuCode } from '../../enums/MenuCode.enum';
import { SubmenuCode } from '../../enums/SubmenuCode.enum';
import { MenuItemModel } from '../../models/menu/MenuItemModel';
import { MenuModel } from '../../models/menu/MenuModel';
import { WebMenuItemsModel } from '../../models/menu/WebMenuItemsModel';
import { IMenuState } from '../../store/menu.state';
import { TenantsService } from '../tenant/tenants.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private tenantsService: TenantsService = inject(TenantsService);
  private translate: TranslateService = inject(TranslateService);
  private store: Store<{ menu: IMenuState }> = inject(
    Store<{ menu: IMenuState }>
  );

  menuStatic1: any;
  menuStatic2: any;

  constructor() {}

  /**
   * @description Adiciona na lista os menus que serão excluídos da pesquisa de menus da navbar e dos menus de acessos rápidos para favoritar
   * @returns {ItemMenuCode[]} ItemMenuCode dos menus a serem excluídos
   */
  getMenuBlacklist(): ItemMenuCode[] {
    return [];
  }

  getAvailableMenuFromTenant(): MenuModel[] {
    this.menuStatic1 = this.getMenu();
    var hasDocumentEmmiter: boolean = false;
    var hasTMS: boolean = false;
    var hasJourney: boolean = false;
    var hasCooperative: boolean = false;
    var hasWms: boolean = false;
    var hasFinance: boolean = false;
    var hasRegistrations: boolean = false;
    var hasSettings: boolean = false;
    var hasFollow: boolean = false;

    this.tenantsService.availableMenus().subscribe({
      next: (menus) => {
        menus.forEach((menu) => {
          switch (menu.codeName) {
            case ItemMenuCode.COMMERCIAL_INVOICE ||
              ItemMenuCode.CRT ||
              ItemMenuCode.MIC ||
              ItemMenuCode.CTE ||
              ItemMenuCode.MDFE ||
              ItemMenuCode.ADUANA_CUSTOMS_AND_ENCLOSURES ||
              ItemMenuCode.SISCOMEX_ROUTE ||
              ItemMenuCode.SUITABILITIES ||
              ItemMenuCode.INSURANCE_POLICY:
              hasDocumentEmmiter = true;
              break;

            case ItemMenuCode.FOLLOW_UP_MIC_EVENTS ||
              ItemMenuCode.FOLLOW_UP_MIC_APPOINTMENT ||
              ItemMenuCode.FOLLOW_UP_MIC_DASHBOARD:
              hasFollow = true;
              break;

            // ADD OS ITENS MENU ASSOCIADOS AO MÓDULO TMS
            case 'ITENS MENU ASSOCIADOS AO MÓDULO TMS':
              hasTMS = true;
              break;

            // ADD OS ITENS MENU ASSOCIADOS AO MÓDULO JOURNEY
            case 'ITENS MENU ASSOCIADOS AO MÓDULO JOURNEY':
              hasJourney = true;
              break;

            // ADD OS ITENS MENU ASSOCIADOS AO MÓDULO COOPERATIVE
            case 'ITENS MENU ASSOCIADOS AO MÓDULO COOPERATIVE':
              hasCooperative = true;
              break;

            // ADD OS ITENS MENU ASSOCIADOS AO MÓDULO WMS
            case 'ITENS MENU ASSOCIADOS AO MÓDULO WMS':
              hasWms = true;
              break;

            // ADD OS ITENS MENU ASSOCIADOS AO MÓDULO FINANCE
            case 'ITENS MENU ASSOCIADOS AO MÓDULO FINANCE':
              hasFinance = true;
              break;

            case ItemMenuCode.COMPANIES_AND_CLIENTS ||
              ItemMenuCode.PROVIDERS ||
              ItemMenuCode.PLACES ||
              ItemMenuCode.DRIVERS ||
              ItemMenuCode.VEHICLES ||
              ItemMenuCode.CURRENCIES:
              hasRegistrations = true;
              break;

            case ItemMenuCode.MY_COMPANIES ||
              ItemMenuCode.USERS ||
              ItemMenuCode.PLAN_AND_BILLING ||
              ItemMenuCode.DIGITAL_CERTIFICATE ||
              ItemMenuCode.PUCOMEX_INTEGRATION ||
              ItemMenuCode.MULTILOG_INTEGRATION:
              hasSettings = true;
              break;
          }
        });

        if (hasDocumentEmmiter) this.menuStatic1[0].active = true;
        if (hasFollow) this.menuStatic1[1].active = true;
        if (hasRegistrations) this.menuStatic1[7].active = true;
        if (hasSettings) this.menuStatic1[8].active = true;
        // if (hasTMS) this.menuStatic1[1].active = true;
        // if (hasJourney) this.menuStatic1[2].active = true;
        // if (hasCooperative) this.menuStatic1[3].active = true;
        // if (hasWms) this.menuStatic1[4].active = true;
        // if (hasFinance) this.menuStatic1[5].active = true;
      },

      error: (error) => {
        console.error(error);
      },
    });

    return this.menuStatic1;
  }

  listOfAllMenusItems(): MenuItemModel[] {
    this.menuStatic2 = this.getMenu();
    var listOfAllMenusItems: MenuItemModel[] = [];

    this.menuStatic2.forEach((menu: any) => {
      menu?.subMenus?.forEach((subMenu: any) => {
        subMenu?.items?.forEach((item: any) => {
          listOfAllMenusItems.push(item);
        });
      });
    });

    return listOfAllMenusItems;
  }

  /**
   * Pesquisar se o que foi digitado tem correspondência entre os itens do menu (menuItemCode) que o usuário tem acesso
   */
  async searchMenuQuery(query: string): Promise<MenuItemModel[]> {
    return new Promise<MenuItemModel[]>((resolve, reject) => {
      var menuItemsTenant: MenuItemModel[] = this.listOfAllMenusItems();
      var returnMenuItems: MenuItemModel[] = [];

      this.store
        .select((state) => state.menu)
        .subscribe({
          next: (menu) => {
            menu.othersAccess.forEach((menuUsuario: WebMenuItemsModel) => {
              menuItemsTenant.forEach((menuTenant) => {
                if (menuTenant.menuItemCode === menuUsuario.codeName) {
                  menuTenant.show = true;
                }
              });
            });

            menu.favorites.forEach((menuUsuario: WebMenuItemsModel) => {
              menuItemsTenant.forEach((menuTenant) => {
                if (menuTenant.menuItemCode === menuUsuario.codeName) {
                  menuTenant.show = true;
                }
              });
            });

            if (query !== null || query !== undefined || query !== '') {
              menuItemsTenant.filter((item) => {
                if (
                  item?.label?.toLowerCase().includes(query?.toLowerCase()) &&
                  item.show
                ) {
                  returnMenuItems.push(item);
                }
              });
            } else {
              returnMenuItems = menuItemsTenant;
            }

            resolve(returnMenuItems);
          },
          error: (error) => {
            console.error(error);
            reject(error);
          },
        });
    });
  }

  /**
   * Retorna a construção completa do menu do sistema, com todos seus módulos, submenus e itens de menu.
   * @returns MenuModel[]
   */
  getMenu() {
    const MENU: MenuModel[] = [
      {
        menuCode: MenuCode.DOCUMENT_ISSUER,
        label: this.translate.instant(
          'enums.menuCode.' + MenuCode.DOCUMENT_ISSUER
        ),
        icon: 'documentos',
        active: false,
        items: [],
        subMenus: [
          {
            submenuCode: SubmenuCode.INTERNATIONAL,
            label: this.translate.instant(
              'enums.submenuCode.' + SubmenuCode.INTERNATIONAL
            ),
            show: false,
            active: true,
            items: [
              // MENU OCULTADO, MAS SERÁ USADO FUTURAMENTE: COMMERCIAL_INVOICE
              // {
              //   menuItemCode: ItemMenuCode.COMMERCIAL_INVOICE,
              //   label: this.translate.instant(
              //     'enums.itemMenuCode.' + ItemMenuCode.COMMERCIAL_INVOICE
              //   ),
              //   icon: 'pi pi-file-o',
              //   url: '/documents-emitter/international/commercial-invoice',
              //   show: false,
              //   infoExtra: this.translate.instant(
              //     'documentsEmitter.emitterSummaryPage.infoExtraCommercialInvoice'
              //   ),
              //   read: false,
              //   write: false,
              // },
              {
                menuItemCode: ItemMenuCode.CRT,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.CRT
                ),
                icon: 'pi pi-file-o',
                url: '/documents-emitter/international/crt',
                show: false,
                infoExtra: this.translate.instant(
                  'documentsEmitter.emitterSummaryPage.infoExtraCrt'
                ),
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.MIC,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.MIC
                ),
                icon: 'pi pi-file-o',
                url: '/documents-emitter/international/mic',
                show: false,
                infoExtra: this.translate.instant(
                  'documentsEmitter.emitterSummaryPage.infoExtraMic'
                ),
                read: false,
                write: false,
              },
            ],
          },
          {
            submenuCode: SubmenuCode.NATIONAL,
            label: this.translate.instant(
              'enums.submenuCode.' + SubmenuCode.NATIONAL
            ),
            show: false,
            active: false,
            items: [
              {
                menuItemCode: ItemMenuCode.CTE,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.CTE
                ),
                icon: 'pi pi-file-o',
                url: '',
                show: false,
                infoExtra: this.translate.instant(
                  'documentsEmitter.emitterSummaryPage.infoExtraCte'
                ),
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.MDFE,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.MDFE
                ),
                icon: 'pi pi-file-o',
                url: '',
                show: false,
                infoExtra: this.translate.instant(
                  'documentsEmitter.emitterSummaryPage.infoExtraMdfe'
                ),
                read: false,
                write: false,
              },
            ],
          },
        ],
      },
      {
        menuCode: MenuCode.FOLLOW_UP_MIC,
        label: 'Follow-UP',
        icon: 'follow-up',
        active: false,
        items: [],
        subMenus: [
          {
            submenuCode: SubmenuCode.FOLLOW_MIC_DTA,
            label: 'MIC/DTA',
            show: false,
            active: true,
            items: [
              {
                menuItemCode: ItemMenuCode.FOLLOW_UP_MIC_EVENTS,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.FOLLOW_UP_MIC_EVENTS
                ),
                icon: 'pi pi-exclamation-triangle',
                url: '/follow-up/mic-dta/follow-up-mic-events',
                show: false,
                infoExtra: '',
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.FOLLOW_UP_MIC_APPOINTMENT,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.FOLLOW_UP_MIC_APPOINTMENT
                ),
                icon: 'pi pi-calendar',
                url: '/follow-up/mic-dta/follow-up-mic-scheduling',
                show: false,
                infoExtra: '',
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.FOLLOW_UP_MIC_DASHBOARD,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.FOLLOW_UP_MIC_DASHBOARD
                ),
                icon: 'pi pi-list',
                url: '/follow-up/mic-dta/follow-up-mic-process',
                show: false,
                infoExtra: '',
                read: false,
                write: false,
              },
            ],
          },
        ],
      },
      {
        menuCode: MenuCode.TMS,
        label: this.translate.instant('enums.menuCode.' + MenuCode.TMS),
        icon: 'tms',
        active: false,
        items: [],
      },
      {
        menuCode: MenuCode.JOURNEY,
        label: this.translate.instant('enums.menuCode.' + MenuCode.JOURNEY),
        icon: 'jornada',
        active: false,
        items: [],
      },
      {
        menuCode: MenuCode.COOPERATIVE,
        label: this.translate.instant('enums.menuCode.' + MenuCode.COOPERATIVE),
        icon: 'cooperativa',
        active: false,
        items: [],
      },
      {
        menuCode: MenuCode.WMS,
        label: this.translate.instant('enums.menuCode.' + MenuCode.WMS),
        icon: 'wms',
        active: false,
        items: [],
      },
      {
        menuCode: MenuCode.FINANCE,
        label: this.translate.instant('enums.menuCode.' + MenuCode.FINANCE),
        icon: 'gestor-financeiro',
        active: false,
        items: [],
      },
      {
        menuCode: MenuCode.REGISTRATIONS,
        label: this.translate.instant(
          'enums.menuCode.' + MenuCode.REGISTRATIONS
        ),
        icon: 'cadastros',
        items: [],
        active: false,
        subMenus: [
          {
            submenuCode: SubmenuCode.GENERAL_REGISTERS,
            label: this.translate.instant(
              'enums.submenuCode.' + SubmenuCode.GENERAL_REGISTERS
            ),
            show: false,
            active: true,
            items: [
              {
                menuItemCode: ItemMenuCode.COMPANIES_AND_CLIENTS,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.COMPANIES_AND_CLIENTS
                ),
                icon: 'pi pi-users',
                url: '/registrations/general/companies-and-clients',
                infoExtra: this.translate.instant(
                  'registrations.registrationsSummaryPage.infoExtraCompaniesAndClients'
                ),
                show: false,
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.PROVIDERS,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.PROVIDERS
                ),
                icon: 'pi pi-users',
                url: '/registrations/general/providers',
                infoExtra: this.translate.instant(
                  'registrations.registrationsSummaryPage.infoExtraProviders'
                ),
                show: false,
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.DRIVERS,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.DRIVERS
                ),
                icon: 'pi pi-users',
                url: '/registrations/general/drivers',
                infoExtra: this.translate.instant(
                  'registrations.registrationsSummaryPage.infoExtraDrivers'
                ),
                show: false,
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.VEHICLES,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.VEHICLES
                ),
                icon: 'pi pi-car',
                url: '/registrations/general/vehicles',
                infoExtra: this.translate.instant(
                  'registrations.registrationsSummaryPage.infoExtraVehicles'
                ),
                show: false,
                read: false,
                write: false,
              },
              // MENUS OCULTADOS, MAS SERÃO USADOS FUTURAMENTE: MOEDAS, LOCALIDADES
              // {
              //   menuItemCode: ItemMenuCode.PLACES,
              //   label: this.translate.instant(
              //     'enums.itemMenuCode.' + ItemMenuCode.PLACES
              //   ),
              //   icon: 'pi pi-map-marker',
              //   url: '/registrations/places',
              //   infoExtra: this.translate.instant(
              //     'registrations.registrationsSummaryPage.infoExtraPlaces'
              //   ),
              //   show: false,
              //   read: false,
              //   write: false,
              // },
              // {
              //   menuItemCode: ItemMenuCode.CURRENCIES,
              //   label: this.translate.instant(
              //     'enums.itemMenuCode.' + ItemMenuCode.CURRENCIES
              //   ),
              //   icon: 'pi pi-dollar',
              //   url: '/registrations/currencies',
              //   infoExtra: this.translate.instant(
              //     'registrations.registrationsSummaryPage.infoExtraCurrencies'
              //   ),
              //   show: false,
              //   read: false,
              //   write: false,
              // },
            ],
          },
          {
            submenuCode: SubmenuCode.EMISSOR_REGISTERS,
            label: this.translate.instant(
              'enums.submenuCode.' + SubmenuCode.EMISSOR_REGISTERS
            ),
            show: false,
            active: true,
            items: [
              // MENU OCULTADO, MAS SERÁ USADO FUTURAMENTE: ADUANAS E RECINTOS
              // {
              //   menuItemCode: ItemMenuCode.ADUANA_CUSTOMS_AND_ENCLOSURES,
              //   label: this.translate.instant(
              //     'enums.itemMenuCode.' +
              //       ItemMenuCode.ADUANA_CUSTOMS_AND_ENCLOSURES
              //   ),
              //   icon: 'pi pi-pencil',
              //   url: 'documents-emitter/emitter-registrations/aduana-customs-and-enclosures',
              //   show: false,
              //   read: false,
              //   write: false,
              // },
              {
                menuItemCode: ItemMenuCode.SISCOMEX_ROUTE,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.SISCOMEX_ROUTE
                ),
                icon: 'pi pi-pencil',
                url: '/registrations/emitter/siscomex-routes',
                show: false,
                infoExtra: this.translate.instant(
                  'documentsEmitter.emitterSummaryPage.infoExtraCustomRoutes'
                ),
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.SUITABILITIES,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.SUITABILITIES
                ),
                icon: 'pi pi-pencil',
                url: '/registrations/emitter/suitabilities',
                show: false,
                infoExtra: this.translate.instant(
                  'documentsEmitter.emitterSummaryPage.infoExtraSuitabilities'
                ),
                read: false,
                write: false,
              },

              {
                menuItemCode: ItemMenuCode.INSURANCE_POLICY,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.INSURANCE_POLICY
                ),
                icon: 'pi pi-pencil',
                url: '/registrations/emitter/insurance-policy',
                show: false,
                infoExtra: this.translate.instant(
                  'documentsEmitter.emitterSummaryPage.infoExtraInsurancePolicy'
                ),
                read: false,
                write: false,
              },
            ],
          },
        ],
      },
      {
        menuCode: MenuCode.SETTINGS,
        label: this.translate.instant('enums.menuCode.' + MenuCode.SETTINGS),
        icon: 'pi pi-cog',
        active: false,
        items: [],
        subMenus: [
          {
            submenuCode: SubmenuCode.MANAGEMENT_REGISTERS,
            label: this.translate.instant(
              'enums.submenuCode.' + SubmenuCode.MANAGEMENT_REGISTERS
            ),
            icon: 'pi pi-cog',
            url: '/settings/general-settings',
            show: false,
            active: true,
            items: [
              {
                menuItemCode: ItemMenuCode.MY_COMPANIES,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.MY_COMPANIES
                ),
                icon: 'pi pi-building',
                url: '/settings/general-settings/management-registers/my-companies',
                show: false,
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.USERS,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.USERS
                ),
                icon: 'pi pi-user',
                url: '/settings/general-settings/management-registers/users',
                show: false,
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.DIGITAL_CERTIFICATE,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.DIGITAL_CERTIFICATE
                ),
                icon: 'pi pi-verified',
                url: '/settings/general-settings/management-registers/digital-certificate',
                show: false,
                read: false,
                write: false,
              },
            ],
          },
          {
            submenuCode: SubmenuCode.INTEGRATIONS,
            label: this.translate.instant(
              'enums.submenuCode.' + SubmenuCode.INTEGRATIONS
            ),
            icon: 'pi pi-cog',
            show: false,
            active: true,
            items: [
              {
                menuItemCode: ItemMenuCode.PUCOMEX_INTEGRATION,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.PUCOMEX_INTEGRATION
                ),
                icon: 'pi pi-cog',
                url: '/settings/general-settings/integrations/pucomex-integration',
                show: false,
                read: false,
                write: false,
              },
              {
                menuItemCode: ItemMenuCode.MULTILOG_INTEGRATION,
                label: this.translate.instant(
                  'enums.itemMenuCode.' + ItemMenuCode.MULTILOG_INTEGRATION
                ),
                icon: 'pi pi-cog',
                url: '/settings/general-settings/integrations/multilog-integration',
                show: false,
                read: false,
                write: false,
              },
            ],
          },
          // MENU OCULTADO, MAS SERÁ USADO FUTURAMENTE: PLAN_BILLING
          // {
          //   submenuCode: SubmenuCode.PLAN_BILLING,
          //   label: this.translate.instant(
          //     'enums.submenuCode.' + SubmenuCode.PLAN_BILLING
          //   ),
          //   icon: 'pi pi-credit-card',
          //   show: false,
          //   active: false,
          //   items: [
          //     {
          //       menuItemCode: ItemMenuCode.PLAN_AND_BILLING,
          //       label: this.translate.instant(
          //         'enums.itemMenuCode.' + ItemMenuCode.PLAN_AND_BILLING
          //       ),
          //       icon: 'pi pi-credit-card',
          //       show: false,
          //       read: false,
          //       write: false,
          //     },
          //   ],
          // },
        ],
      },
    ];

    return MENU;
  }
}
