import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import {
  LangChangeEvent,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';
import { ItemMenuCode } from 'src/app/core/common/enums/ItemMenuCode.enum';
import { MenuCode } from 'src/app/core/common/enums/MenuCode.enum';
import { SubmenuCode } from 'src/app/core/common/enums/SubmenuCode.enum';
import { MenuItemModel } from 'src/app/core/common/models/menu/MenuItemModel';
import { LanguageService } from 'src/app/core/common/services/language/language.service';
import { MenuService } from 'src/app/core/common/services/menu/menu.service';
import { TenantsService } from 'src/app/core/common/services/tenant/tenants.service';
import { IContractState } from 'src/app/core/common/store/contract.state';
import { IMenuState } from 'src/app/core/common/store/menu.state';
import { environment } from 'src/environments/environment';
import { LoadMenuComponent } from '../../load/load-menu/load-menu.component';

export class ItemMenuModel {
  tooltip?: string;
  icon?: string;
  action?: (event?: any) => void;
  disabled?: boolean;
  hasBadge?: boolean;
}

/**
 * * Componente de menu lateral
 * @description Esse componente é responsável por montar e exibir o menu lateral, seus submenus e itens. *
 *
 * @author Dariane Abich
 * @date 23/04/2024
 * @export
 * @class AppMenuComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  standalone: true,
  imports: [
    BadgeModule,
    CommonModule,
    TooltipModule,
    TooltipModule,
    PanelMenuModule,
    TranslateModule,
    ScrollPanelModule,
    LoadMenuComponent,
  ],
})
export class AppMenuComponent implements OnInit {
  private translate: TranslateService = inject(TranslateService);
  private langService: LanguageService = inject(LanguageService);
  private menuService: MenuService = inject(MenuService);
  private router: Router = inject(Router);
  private tenantService: TenantsService = inject(TenantsService);
  private menuStore: Store<{ menu: IMenuState }> = inject(
    Store<{ menu: IMenuState }>
  );
  private moduleStote: Store<{ module: IContractState }> = inject(
    Store<{ module: IContractState }>
  );

  emitterSubmenus: MenuItem[] = [];
  tmsSubmenus: MenuItem[] = [];
  journeySubmenus: MenuItem[] = [];
  coopSubmenus: MenuItem[] = [];
  wmsSubmenus: MenuItem[] = [];
  managerSubmenus: MenuItem[] = [];
  registrationsSubmenus: MenuItem[] = [];
  followUpSubmenus: MenuItem[] = [];
  emitter: boolean = false;
  tms: boolean = false;
  wms: boolean = false;
  coop: boolean = false;
  journey: boolean = false;
  manager: boolean = false;
  registrations: boolean = false;
  followUp: boolean = false;
  fileImport = faFileImport;
  menuItems: ItemMenuModel[] = [];
  stateMenuLocal: boolean = false;
  showMainMenu: boolean = false;

  webMenuItems: any[] = [];
  allMenusTenant: any;

  internationalItems: MenuItemModel[] = [];
  nationalItems: MenuItemModel[] = [];
  emitterRegistrationsItems: MenuItemModel[] = [];
  registrationsItems: MenuItemModel[] = [];
  followUpItems: MenuItemModel[] = [];
  hasEmitter: boolean = false;
  hasEmitterModule: boolean = false;
  hasRegistrations: boolean = false;
  hasFollowUp: boolean = false;
  hasFollowUpModule: boolean = false;
  loadingMenu: boolean = true;
  @Output() stateMenu = new EventEmitter<boolean>();
  menus$ = new Observable<IMenuState>();
  modules$ = new Observable<IContractState>();
  modules: any[] = [];

  resetTime: any;
  timeoutId: any;

  version: string = environment.appVersion ?? '';

  constructor() {
    this.modules$ = this.moduleStote.select('module');
    this.menus$ = this.menuStore.select('menu');
  }

  ngOnInit(): void {
    this.getModules();
    this.allMenusTenant = this.menuService.getMenu();
    // this.getMenus();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langService.setLangFromLocalStorage();
      this.getModules();
      this.allMenusTenant = this.menuService.getMenu();
    });

    this.resetTime = 15000;
    this.timeoutId = null;
  }

  /**
   * @description Carregar os menus e permissões do usuário
   */
  getMenus() {
    this.menuStore
      .select((state) => state.menu.permissions)
      .subscribe((permissions) => {
        this.webMenuItems = permissions;
        this.checkMenus();
      });
  }

  getModules() {
    this.tenantService.getModules().then((modules) => {
      this.modules = modules;
      this.getMenus();
    });
  }

  /**
   * @description Verifica se o usuário tem permissão para acessar os menus
   */
  async checkMenus() {
    await this.allMenusTenant.forEach((menu: any) => {
      if (menu.menuCode === MenuCode.DOCUMENT_ISSUER) {
        menu.subMenus?.forEach((subMenu: any) => {
          if (subMenu.submenuCode === SubmenuCode.INTERNATIONAL) {
            subMenu.items?.forEach((internationalItem: any) => {
              let itemFound = this.webMenuItems?.find(
                (item) => item.codeName === internationalItem.menuItemCode
              );
              if (itemFound) this.internationalItems.push(internationalItem);
            });

            if (this.internationalItems.length > 0) this.hasEmitter = true;
          } else if (subMenu.submenuCode === SubmenuCode.NATIONAL) {
            subMenu.items?.forEach((nationalItem: any) => {
              let itemFound = this.webMenuItems?.find(
                (item) => item.codeName === nationalItem.menuItemCode
              );
              if (itemFound) this.nationalItems.push(nationalItem);
            });
          }
        });

        if (
          this.internationalItems.length > 0 ||
          this.nationalItems.length > 0
        ) {
          this.modules.forEach((module: any) => {
            if (module.name === MenuCode.DOCUMENT_ISSUER) {
              this.hasEmitter = true;
              this.hasEmitterModule = true;
            }
          });
        }
      }

      if (menu.menuCode === MenuCode.FOLLOW_UP_MIC) {
        menu.subMenus?.forEach((subMenu: any) => {
          if (subMenu.submenuCode === SubmenuCode.FOLLOW_MIC_DTA) {
            subMenu.items?.forEach((follow: any) => {
              let itemFound = this.webMenuItems?.find(
                (item) => item.codeName === follow.menuItemCode
              );

              if (itemFound) this.followUpItems.push(follow);
            });
          }
        });

        if (this.followUpItems.length > 0) {
          this.modules.forEach((module: any) => {
            if (module.name === MenuCode.FOLLOW_UP_MIC) {
              this.hasFollowUpModule = true;
              this.hasFollowUp = true;
            }
          });
        }
      }

      if (menu.menuCode === MenuCode.TMS) {
      }
      if (menu.menuCode === MenuCode.WMS) {
      }
      if (menu.menuCode === MenuCode.COOPERATIVE) {
      }
      if (menu.menuCode === MenuCode.JOURNEY) {
      }
      if (menu.menuCode === MenuCode.FINANCE) {
      }

      if (menu.menuCode === MenuCode.REGISTRATIONS) {
        menu.subMenus?.forEach((subMenu: any) => {
          if (subMenu.submenuCode === SubmenuCode.GENERAL_REGISTERS) {
            subMenu.items?.forEach((registrationItems: any) => {
              let itemFound = this.webMenuItems?.find(
                (item) => item.codeName === registrationItems.menuItemCode
              );
              if (itemFound) this.registrationsItems.push(registrationItems);
            });

            if (this.registrationsItems.length > 0)
              this.hasRegistrations = true;
          } else if (subMenu.submenuCode === SubmenuCode.EMISSOR_REGISTERS) {
            subMenu.items?.forEach((eRegistrationsItems: any) => {
              let itemFound = this.webMenuItems?.find(
                (item) => item.codeName === eRegistrationsItems.menuItemCode
              );
              if (itemFound)
                this.emitterRegistrationsItems.push(eRegistrationsItems);
            });

            if (this.emitterRegistrationsItems.length > 0)
              this.hasEmitter = true;
          }
        });
      }

      setTimeout(() => {
        this.loadingMenu = false;
      }, 600);
    });

    this.composeMenu();
    this.composeSubmenu();
  }

  /**
   * @description Montar o menu principal
   */
  composeMenu() {
    this.menuItems = [
      {
        tooltip: this.translate.instant(
          'enums.menuCode.' + MenuCode.DOCUMENT_ISSUER
        ),
        icon: 'documentos',
        action: () => this.enableEmitterMenu(),
        disabled: this.hasEmitter && this.hasEmitterModule ? false : true,
      },
      {
        tooltip: this.translate.instant(
          'enums.menuCode.' + MenuCode.FOLLOW_UP_MIC
        ),
        icon: 'follow-up',
        action: () => this.enableFollowUpMenu(),
        disabled: this.hasFollowUp && this.hasFollowUpModule ? false : true,
        hasBadge: true,
      },
      {
        tooltip: this.translate.instant('enums.menuCode.' + MenuCode.TMS),
        icon: 'tms',
        action: () => this.enableTmsMenu(),
        disabled: true,
      },
      {
        tooltip: this.translate.instant('enums.menuCode.' + MenuCode.JOURNEY),
        icon: 'jornada',
        action: () => this.enableJourneyMenu(),
        disabled: true,
      },
      {
        tooltip: this.translate.instant(
          'enums.menuCode.' + MenuCode.COOPERATIVE
        ),
        icon: 'cooperativa',
        action: () => this.enableCoopMenu(),
        disabled: true,
      },
      {
        tooltip: this.translate.instant('enums.menuCode.' + MenuCode.WMS),
        icon: 'wms',
        action: () => this.enableWmsMenu(),
        disabled: true,
      },
      {
        tooltip: this.translate.instant('enums.menuCode.' + MenuCode.FINANCE),
        icon: 'gestor-financeiro',
        action: () => this.enableManagerMenu(),
        disabled: true,
      },

      {
        tooltip: this.translate.instant(
          'enums.menuCode.' + MenuCode.REGISTRATIONS
        ),
        icon: 'cadastros',
        action: () => this.enableRegistrationsMenu(),
        disabled:
          (this.registrationsItems.length === 0 && !this.hasEmitterModule) ||
          (this.registrationsItems.length === 0 &&
            this.hasFollowUpModule &&
            this.emitterRegistrationsItems.length === 0) ||
          (this.registrationsItems.length === 0 &&
            this.emitterRegistrationsItems.length === 0)
            ? true
            : false,
      },
    ];
  }

  /**
   * @description Montar os submenus
   */
  composeSubmenu() {
    /*   EMISSOR   */
    this.emitterSubmenus = [
      {
        label: this.translate.instant(
          'enums.submenuCode.' + SubmenuCode.INTERNATIONAL
        ),
        visible: this.internationalItems.length! > 0,
        items: [
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.COMMERCIAL_INVOICE
            ),
            icon: 'pi pi-file-o',
            routerLink: ['documents-emitter/international/commercial-invoice'],
            visible: this.checkAvailableItems(
              this.internationalItems,
              ItemMenuCode.COMMERCIAL_INVOICE
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.CRT
            ),
            icon: 'pi pi-file-o',
            routerLink: ['documents-emitter/international/crt'],
            visible: this.checkAvailableItems(
              this.internationalItems,
              ItemMenuCode.CRT
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.MIC
            ),
            icon: 'pi pi-file-o',
            routerLink: ['documents-emitter/international/mic'],
            disabled: false,
            visible: this.checkAvailableItems(
              this.internationalItems,
              ItemMenuCode.MIC
            ),
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: this.translate.instant(
          'enums.submenuCode.' + SubmenuCode.NATIONAL
        ),
        visible: this.nationalItems.length! > 0,
        disabled: true,
        items: [
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.CTE
            ),
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
            disabled: true,
            visible: this.checkAvailableItems(
              this.nationalItems,
              ItemMenuCode.CTE
            ),
          },
          {
            label: 'NF-e Importadas',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
            disabled: true,
            visible: false,
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.MDFE
            ),
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
            disabled: true,
            visible: this.checkAvailableItems(
              this.nationalItems,
              ItemMenuCode.MDFE
            ),
          },
        ],
      },
    ];

    this.followUpSubmenus = [
      {
        label: this.translate.instant(
          'enums.submenuCode.' + SubmenuCode.FOLLOW_MIC_DTA
        ),
        visible: this.followUpItems.length > 0 ? true : false,
        items: [
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.FOLLOW_UP_MIC_EVENTS
            ),
            icon: 'pi pi-exclamation-triangle',
            routerLink: ['/follow-up/mic-dta/follow-up-mic-events'],
            visible: this.checkAvailableItems(
              this.followUpItems,
              ItemMenuCode.FOLLOW_UP_MIC_EVENTS
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.FOLLOW_UP_MIC_APPOINTMENT
            ),
            icon: 'pi pi-calendar',
            routerLink: ['/follow-up/mic-dta/follow-up-mic-scheduling'],
            visible: this.checkAvailableItems(
              this.followUpItems,
              ItemMenuCode.FOLLOW_UP_MIC_APPOINTMENT
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.FOLLOW_UP_MIC_DASHBOARD
            ),
            icon: 'pi pi-list',
            routerLink: ['/follow-up/mic-dta/follow-up-mic-process'],
            visible: this.checkAvailableItems(
              this.followUpItems,
              ItemMenuCode.FOLLOW_UP_MIC_DASHBOARD
            ),
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
    ];

    /*   TMS   */
    this.tmsSubmenus = [
      {
        label: 'Viagens',
        items: [
          {
            label: 'Rastreio',
            icon: 'pi pi-map-marker',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Programação',
            icon: 'pi pi-map-marker',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: 'Operacional',
        items: [
          {
            label: 'Ordens em Curso',
            icon: 'pi pi-map-marker',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: 'Logística',
        items: [
          {
            label: 'Distâncias',
            icon: 'pi pi-map-marker',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Rotas',
            icon: 'pi pi-map-marker',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: 'Cadastros',
        items: [
          {
            label: 'Tipo de Rota',
            icon: 'pi pi-pencil',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        separator: true,
        styleClass: 'separator',
        disabled: true,
      },
      {
        label: 'Dashboard',
        styleClass: 'customized',
        icon: 'pi pi-chart-pie',
      },
    ];

    /*   JORNADA   */
    this.journeySubmenus = [
      {
        label: 'Jornada',
        items: [
          {
            label: 'Viagens',
            icon: 'pi pi-map-marker',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Motoristas',
            icon: 'pi pi-user',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Livro Ponto',
            icon: 'pi pi-book',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Despesas',
            icon: 'pi pi-dollar',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: 'Cadastros',
        items: [
          {
            label: 'Motoristas',
            icon: 'pi pi-pencil',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        separator: true,
        styleClass: 'separator',
        disabled: true,
      },
      {
        label: 'Dashboard',
        styleClass: 'customized',
        icon: 'pi pi-chart-pie',
      },
    ];

    /*   COOPERATIVA   */
    this.coopSubmenus = [
      {
        label: 'Associados',
        items: [
          {
            label: 'Associado',
            icon: 'pi pi-user',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Ficha Proposta',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Matrícula',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Mensalidades',
            icon: 'pi pi-calendar',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Valores Mensalidades',
            icon: 'pi pi-dollar',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Gerenciar Veículos',
            icon: 'pi pi-car',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: 'RAC',
        items: [
          {
            label: 'Veículos RAC',
            icon: 'pi pi-car',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Regulamento',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Termo de Adesão',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Cadastros RAC',
            icon: 'pi pi-id-card',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Cobrança',
            icon: 'pi pi-dollar',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Encerramento',
            icon: 'pi pi-stop-circle',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Lançamentos',
            icon: 'pi pi-send',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: 'Mensalidade',
        items: [
          {
            label: 'Cobrança',
            icon: 'pi pi-dollar',
            command: () => this.closeMenuOnTime(),
          },
          {
            label: 'Referência Valores',
            icon: 'pi pi-history',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: 'Permisos',
        items: [
          { label: 'Gestão', icon: 'pi pi-cog', command: () => {} },
          {
            label: 'Cobranças',
            icon: 'pi pi-dollar',
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        separator: true,
        styleClass: 'separator',
        disabled: true,
      },
      {
        label: 'Dashboard',
        styleClass: 'customized',
        icon: 'pi pi-chart-pie',
      },
    ];

    /*   WMS   */
    this.wmsSubmenus = [
      {
        label: 'Armazenagem',
        items: [
          {
            label: 'Pré-entrada',
            expanded: true,
            disabled: true,
            items: [
              {
                label: 'Conciliação',
                icon: 'pi pi-copy',
                routerLink: ['/'],
                command: () => this.closeMenuOnTime(),
              },
              { separator: true },
              {
                label: 'Conferência',
                icon: 'pi pi-search',
                routerLink: [''],
                command: () => this.closeMenuOnTime(),
              },
            ],
          },
          {
            label: 'Entrada',
            expanded: true,
            disabled: true,
            items: [
              {
                label: 'Recebimento',
                icon: 'pi pi-caret-down',
                routerLink: [''],
                command: () => this.closeMenuOnTime(),
              },
              { separator: true },
              {
                label: 'Movimentar Estoque',
                icon: 'pi pi-forward',
                routerLink: [''],
                command: () => this.closeMenuOnTime(),
              },
            ],
          },
          {
            label: 'Estoque',
            expanded: true,
            disabled: true,
            items: [
              {
                label: 'Gerenc. de Paletes',
                icon: 'pi pi-box',
                routerLink: [''],
                command: () => this.closeMenuOnTime(),
              },
              { separator: true },
              {
                label: 'Movimentações',
                icon: 'pi pi-forward',
                routerLink: [''],
                command: () => this.closeMenuOnTime(),
              },
            ],
          },
          {
            label: 'Avarias',
            expanded: true,
            disabled: true,
            items: [
              {
                label: 'Avarias',
                icon: 'pi pi-exclamation-triangle',
                routerLink: [''],
                command: () => this.closeMenuOnTime(),
              },
            ],
          },
          {
            label: 'Portal Único Siscomex',
            expanded: true,
            disabled: true,
            items: [
              {
                label: 'Recepção',
                icon: 'pi pi-globe',
                routerLink: [''],
                command: () => this.closeMenuOnTime(),
              },
            ],
          },
        ],
      },
      {
        label: 'Ordem de Expedição',
        items: [
          {
            label: 'Gerar',
            icon: 'pi pi-plus-circle',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Autorizar',
            icon: 'pi pi-check-circle',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Iniciar',
            icon: 'pi pi-play',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Conferência',
            icon: 'pi pi-search',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Iniciar Carregamento',
            icon: 'pi pi-qrcode',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Confirmação de Carregamento',
            icon: 'pi pi-check-circle',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Concluir Expedição',
            icon: 'pi pi-check-circle',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'OE Canceladas',
            icon: 'pi pi-times-circle',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Avarias',
            icon: 'pi pi-exclamation-triangle',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Transportador',
        items: [
          {
            label: 'Descarregamento de Armazenagem',
            icon: 'pi pi-file-o',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Descarregamento de Expedição',
            icon: 'pi pi-file-o',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Previsões',
            icon: 'pi pi-calendar',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Documentos',
        items: [
          {
            label: 'Notas Fiscais',
            icon: 'pi pi-file-o',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'AVIEXP',
            icon: 'pi pi-file-o',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Agendamento de Expedições',
            icon: 'pi pi-clock',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Anomalias',
            icon: 'pi pi-exclamation-triangle',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Consultas',
        items: [
          {
            label: 'Aprovisonadores',
            icon: 'pi pi-sliders-v',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Administração',
        items: [
          {
            label: 'Clientes Armazém',
            icon: 'pi pi-users',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Produtos',
            icon: 'pi pi-box',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Áreas de Expedições',
            icon: 'pi pi-map',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Faturamento',
        items: [
          {
            label: 'Valor do serviço',
            icon: 'pi pi-dollar',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Relatórios',
        items: [
          {
            label: 'Estoque',
            icon: 'pi pi-print',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Paletes em estoque',
            icon: 'pi pi-print',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Cadastros',
        items: [
          {
            label: 'Transportador',
            icon: 'pi pi-pencil',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Embalagem',
            icon: 'pi pi-pencil',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Destinatários',
            icon: 'pi pi-pencil',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Armazém',
            icon: 'pi pi-pencil',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Fornecedores',
            icon: 'pi pi-pencil',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Referência da Embalagem',
            icon: 'pi pi-pencil',
            routerLink: [''],
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        separator: true,
        styleClass: 'separator',
        disabled: true,
      },
      {
        label: 'Dashboard',
        styleClass: 'customized',
        icon: 'pi pi-chart-pie',
      },
    ];

    /*   GESTOR   */
    this.managerSubmenus = [
      {
        label: 'Pagamentos',
        items: [
          {
            label: 'Contas a pagar',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Pagamento aos Fornecedores',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Recebimentos',
        items: [
          {
            label: 'Contas a Receber',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Parametrização de Títulos',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Fluxo de Caixa',
        items: [
          {
            label: 'Lançamentos',
            icon: 'pi pi-arrow-up-right',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Contas Bancárias',
        items: [
          {
            label: 'Gerenciamento',
            icon: 'pi pi-cog',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Títulos e Boletos',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Remessa',
            icon: 'pi pi-copy',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        label: 'Naturezas',
        items: [
          {
            label: 'Plano Financeiro',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
          {
            label: 'Natureza Financeira',
            icon: 'pi pi-file-o',
            command: () => this.closeMenuOnTime(),
          },
          { separator: true },
        ],
      },
      {
        separator: true,
        styleClass: 'separator',
        disabled: true,
      },
      {
        label: 'Cotações',
        styleClass: 'customized',
        icon: 'pi pi-dollar',
      },
      {
        label: 'Dashboard',
        styleClass: 'customized',
        icon: 'pi pi-chart-pie',
      },
    ];

    /*   CADASTROS   */
    this.registrationsSubmenus = [
      {
        label: this.translate.instant(
          'enums.submenuCode.' + SubmenuCode.GENERAL_REGISTERS
        ),
        visible: this.registrationsItems.length > 0 ? true : false,
        items: [
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.COMPANIES_AND_CLIENTS
            ),
            icon: 'pi pi-users',
            routerLink: ['/registrations/general/companies-and-clients'],
            visible: this.checkAvailableItems(
              this.registrationsItems,
              ItemMenuCode.COMPANIES_AND_CLIENTS
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.PROVIDERS
            ),
            // styleClass: 'inOperation',
            disabled: true,
            icon: 'pi pi-users',
            routerLink: ['/registrations/general/providers'],
            visible: this.checkAvailableItems(
              this.registrationsItems,
              ItemMenuCode.PROVIDERS
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.PLACES
            ),
            icon: 'pi pi-map-marker',
            routerLink: ['/registrations/general/places'],
            visible: this.checkAvailableItems(
              this.registrationsItems,
              ItemMenuCode.PLACES
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.DRIVERS
            ),
            icon: 'pi pi-users',
            routerLink: ['/registrations/general/drivers'],
            visible: this.checkAvailableItems(
              this.registrationsItems,
              ItemMenuCode.DRIVERS
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.VEHICLES
            ),
            icon: 'pi pi-car',
            routerLink: ['/registrations/general/vehicles'],
            visible: this.checkAvailableItems(
              this.registrationsItems,
              ItemMenuCode.VEHICLES
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.CURRENCIES
            ),
            icon: 'pi pi-dollar',
            routerLink: ['/registrations/general/currencies'],
            disabled: false,
            visible: this.checkAvailableItems(
              this.registrationsItems,
              ItemMenuCode.CURRENCIES
            ),
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
      {
        label: this.translate.instant(
          'enums.submenuCode.' + SubmenuCode.EMISSOR_REGISTERS
        ),
        visible: this.hasEmitterModule
          ? this.emitterRegistrationsItems.length > 0
            ? true
            : false
          : false,
        items: [
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.ADUANA_CUSTOMS_AND_ENCLOSURES
            ),
            icon: 'pi pi-pencil',
            routerLink: [
              '/registrations/emitter/aduana-customs-and-enclosures',
            ],
            visible: this.checkAvailableItems(
              this.emitterRegistrationsItems,
              ItemMenuCode.ADUANA_CUSTOMS_AND_ENCLOSURES
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.SISCOMEX_ROUTE
            ),
            routerLink: ['/registrations/emitter/siscomex-routes'],
            icon: 'pi pi-pencil',
            disabled: false,
            visible: this.checkAvailableItems(
              this.emitterRegistrationsItems,
              ItemMenuCode.SISCOMEX_ROUTE
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.SUITABILITIES
            ),
            icon: 'pi pi-pencil',
            routerLink: ['/registrations/emitter/suitabilities'],
            visible: this.checkAvailableItems(
              this.emitterRegistrationsItems,
              ItemMenuCode.SUITABILITIES
            ),
            command: () => this.closeMenuOnTime(),
          },
          {
            label: this.translate.instant(
              'enums.itemMenuCode.' + ItemMenuCode.INSURANCE_POLICY
            ),
            icon: 'pi pi-pencil',
            routerLink: ['/registrations/emitter/insurance-policy'],
            visible: this.checkAvailableItems(
              this.emitterRegistrationsItems,
              ItemMenuCode.INSURANCE_POLICY
            ),
            command: () => this.closeMenuOnTime(),
          },
        ],
      },
    ];

    this.showMainMenu = true;
  }

  /**
   * @description Habilita o menu do Emissor de Documentos
   */
  enableEmitterMenu() {
    this.emitter = !this.emitter;
    this.tms = false;
    this.journey = false;
    this.coop = false;
    this.wms = false;
    this.manager = false;
    this.registrations = false;
    this.followUp = false;
    this.checkStateMenu();
  }

  /**
   * @description Habilita o menu do TMS
   */
  enableTmsMenu() {
    this.emitter = false;
    this.tms = !this.tms;
    this.journey = false;
    this.coop = false;
    this.wms = false;
    this.manager = false;
    this.registrations = false;
    this.followUp = false;
    this.checkStateMenu();
  }

  /**
   * @description Habilita o menu do Jornada
   */
  enableJourneyMenu() {
    this.emitter = false;
    this.tms = false;
    this.journey = !this.journey;
    this.coop = false;
    this.wms = false;
    this.manager = false;
    this.registrations = false;
    this.followUp = false;
    this.checkStateMenu();
  }

  /**
   * @description Habilita o menu do Cooperativa
   */
  enableCoopMenu() {
    this.emitter = false;
    this.tms = false;
    this.journey = false;
    this.coop = !this.coop;
    this.wms = false;
    this.manager = false;
    this.registrations = false;
    this.followUp = false;
    this.checkStateMenu();
  }

  /**
   * @description Habilita o menu do WMS
   */
  enableWmsMenu() {
    this.emitter = false;
    this.tms = false;
    this.journey = false;
    this.coop = false;
    this.wms = !this.wms;
    this.manager = false;
    this.registrations = false;
    this.followUp = false;
    this.checkStateMenu();
  }

  /**
   * @description Habilita o menu do Gestor
   */
  enableManagerMenu() {
    this.emitter = false;
    this.tms = false;
    this.journey = false;
    this.coop = false;
    this.wms = false;
    this.manager = !this.manager;
    this.registrations = false;
    this.followUp = false;
    this.checkStateMenu();
  }

  /**
   * @description Habilita o menu do Cadastros
   */
  enableRegistrationsMenu() {
    this.emitter = false;
    this.tms = false;
    this.journey = false;
    this.coop = false;
    this.wms = false;
    this.manager = false;
    this.registrations = !this.registrations;
    this.followUp = false;
    this.checkStateMenu();
  }

  /**
   * @description Habilita o menu do Follow Up
   */
  enableFollowUpMenu() {
    this.emitter = false;
    this.tms = false;
    this.journey = false;
    this.coop = false;
    this.wms = false;
    this.manager = false;
    this.registrations = false;
    this.followUp = !this.followUp;
    this.checkStateMenu();
  }

  /**
   * @description Verifica se o menu está aberto ou fechado
   */
  checkStateMenu() {
    if (
      this.emitter === false &&
      this.tms === false &&
      this.journey === false &&
      this.coop === false &&
      this.wms === false &&
      this.manager === false &&
      this.registrations === false &&
      this.followUp === false
    ) {
      this.stateMenu.emit(false);
      this.stateMenuLocal = false;
    } else {
      this.stateMenu.emit(true);
      this.stateMenuLocal = true;
    }
  }

  /**
   * @description alterar cor do menu selecionado
   * @returns
   */
  colorSelected(): string {
    var selected = '';

    if (this.emitter) selected = 'documentos';
    if (this.tms) selected = 'tms';
    if (this.journey) selected = 'jornada';
    if (this.coop) selected = 'cooperativa';
    if (this.wms) selected = 'wms';
    if (this.manager) selected = 'gestor-financeiro';
    if (this.registrations) selected = 'cadastros';
    if (this.followUp) selected = 'follow-up';

    return selected;
  }

  /**
   * @description Verifica se o item do menu está disponível
   * @param array MenuItemModel[] - Array de itens do menu
   * @param code string - Código do item do menu
   * @returns
   */
  checkAvailableItems(array: MenuItemModel[], code: string): boolean {
    let result = array.filter((item) => item.menuItemCode === code);

    if (result.length > 0) return true;
    return false;
  }

  /**
   * @description Fecha o menu após um tempo determinado
   */
  closeMenuOnTime() {
    if (this.timeoutId) clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.emitter = false;
      this.tms = false;
      this.journey = false;
      this.coop = false;
      this.wms = false;
      this.manager = false;
      this.registrations = false;
      this.followUp = false;
      this.checkStateMenu();
    }, this.resetTime);
  }
}
