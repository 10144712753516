<section id="redefinir-senha-modal-list">
  <div class="dialog-section">
    <form [formGroup]="passwordForm">
      <div class="grid-form">
        <div class="grid-content">
          <div class="field grid-0-100" *ngIf="hasOldPassword">
            <label for="currentPassword" class="block p-default">{{
              "common.enterCurrentPassword" | translate
            }}</label>
            <div class="p-input p-fluid">
              <p-password
                id="currentPassword"
                formControlName="currentPassword"
                [toggleMask]="true"
                [feedback]="false"
                styleClass="p-password p-component p-inputwrapper p-input-icon-right"
              ></p-password>
            </div>
          </div>

          <div class="field grid-0-100">
            <label for="newPassword" class="block p-default"
              >{{ "common.newPassword" | translate }}
            </label>
            <p-password
              id="newPassword"
              formControlName="newPassword"
              appendTo="body"
              class="p-fluid"
              [toggleMask]="true"
              styleClass="p-password p-component p-inputwrapper p-input-icon-right"
              weakLabel="{{ 'common.weak' | translate }}"
              mediumLabel="{{ 'common.medium' | translate }}"
              strongLabel="{{ 'common.strong' | translate }}"
              promptLabel="{{ 'common.enterNewPassword' | translate }}"
              [strongRegex]="strongRegex"
              (onBlur)="
                verifyNewPassword(
                  passwordForm.get('newPassword'),
                  passwordForm.get('confirmNewPassword')
                )
              "
            >
              <ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="mt-2 footer-title">
                  {{ "common.passwordMustContain" | translate }}
                </p>
                <ul
                  class="pl-2 ml-2 mt-0 remove-point"
                  style="line-height: 1.5"
                >
                  <li class="p-default">
                    <i
                      *ngIf="requiredValid"
                      [ngClass]="{
                        'item-success': minLengthValid,
                        'item-error': !minLengthValid
                      }"
                      [attr.class]="
                        minLengthValid ? 'pi pi-check' : 'pi pi-times'
                      "
                    ></i>
                    {{ "messages.min8characters" | translate }}
                  </li>
                  <li>
                    <i
                      *ngIf="requiredValid"
                      [ngClass]="{
                        'item-success': requiresDigitValid,
                        'item-error': !requiresDigitValid
                      }"
                      [attr.class]="
                        requiresDigitValid
                          ? 'pi pi-check green-icon'
                          : 'pi pi-times red-icon'
                      "
                    ></i>
                    {{ "messages.min1number" | translate }}
                  </li>
                  <li class="p-default">
                    <i
                      *ngIf="requiredValid"
                      [ngClass]="{
                        'item-success': requiresUppercaseValid,
                        'item-error': !requiresUppercaseValid
                      }"
                      [attr.class]="
                        requiresUppercaseValid ? 'pi pi-check' : 'pi pi-times'
                      "
                    ></i>
                    {{ "messages.min1uppercaseLetter" | translate }}
                  </li>
                  <li>
                    <i
                      *ngIf="requiredValid"
                      [ngClass]="{
                        'item-success': requiresLowercaseValid,
                        'item-error': !requiresLowercaseValid
                      }"
                      [attr.class]="
                        requiresLowercaseValid
                          ? 'pi pi-check green-icon'
                          : 'pi pi-times red-icon'
                      "
                    ></i>
                    {{ "messages.min1lowercaseLetter" | translate }}
                  </li>
                  <li class="p-default">
                    <i
                      *ngIf="requiredValid"
                      [ngClass]="{
                        'item-success': requiresSpecialCharsValid,
                        'item-error': !requiresSpecialCharsValid
                      }"
                      [attr.class]="
                        requiresSpecialCharsValid
                          ? 'pi pi-check'
                          : 'pi pi-times'
                      "
                    ></i>
                    {{ "messages.min1specialCharacter" | translate }}
                  </li>
                </ul>
              </ng-template>
            </p-password>
            <div class="pt-1">
              <template-message-low
                *ngIf="showErrorMessageNewPassword"
                severity="error"
                [textMessage]="'messages.needInformNewPassword' | translate"
              >
              </template-message-low>
            </div>
          </div>

          <div class="field grid-0-100">
            <label for="confirmNewPassword" class="block p-default">{{
              "common.confirmNewPassword" | translate
            }}</label>
            <div class="p-input p-fluid">
              <p-password
                id="confirmNewPassword"
                formControlName="confirmNewPassword"
                [toggleMask]="true"
                [feedback]="false"
                styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                (onBlur)="
                  verifyNewPassword(
                    passwordForm.get('newPassword'),
                    passwordForm.get('confirmNewPassword')
                  )
                "
              ></p-password>
            </div>
            <div class="pt-1">
              <template-message-low
                *ngIf="showErrorMessageConfirmNewPassword"
                severity="error"
                [textMessage]="
                  'messages.youNeedToConfirmYourPassword' | translate
                "
              >
              </template-message-low>

              <template-message-low
                *ngIf="passwordForm?.controls?.['confirmNewPassword']?.touched && passwordForm.controls['confirmNewPassword'].hasError('matching') && !showErrorMessageConfirmNewPassword"
                severity="error"
                [textMessage]="
                  'messages.thePasswordConfirmationDoesNotMatchTheNewPasswordEntered'
                    | translate
                "
              >
              </template-message-low>

              <template-message-low
                *ngIf="passwordForm?.controls?.['confirmNewPassword']?.touched && passwordForm.controls['confirmNewPassword'].hasError('minlength') && !showErrorMessageConfirmNewPassword && !passwordForm.controls['confirmNewPassword'].hasError('matching')"
                severity="error"
                [textMessage]="
                  'messages.thePasswordMustContainAtleast8characters'
                    | translate
                "
              >
              </template-message-low>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="dialog-section__footer">
      <div class="dialog-section__footer__left"></div>
      <div class="dialog-section__footer__right">
        <button
          pButton
          label="{{ 'common.cancel' | translate }}"
          type="button"
          iconPos="left"
          class="p-button-outlined primary"
          (click)="onClose()"
          pTooltip="{{ 'common.cancel' | translate }}"
          [disabled]="savingPassword"
          tooltipPosition="bottom"
          [showDelay]="300"
        ></button>

        <button
          pButton
          label="{{ 'common.save' | translate }}"
          type="button"
          [icon]="savingPassword ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
          iconPos="left"
          class="p-button-raised primary"
          [disabled]="passwordForm.invalid || savingPassword"
          pTooltip="{{ 'common.save' | translate }}"
          tooltipPosition="bottom"
          [showDelay]="300"
          (click)="changePasswordClick()"
        ></button>
      </div>
    </div>
  </div>
</section>
