import { createReducer, on } from '@ngrx/store';
import { CrtFilter } from '../../document-emitter/filters/CrtFilter';
import { MicFilter } from '../../document-emitter/filters/MicFilter';
import { DashboardProcessFilter } from '../../follow-up/filters/DashboardProcessFilter';
import * as FiltersAction from './filters.action';

export interface IFiltersState {
  crtFilter: CrtFilter | null;
  micFilter: MicFilter | null;
  followFilter: DashboardProcessFilter | null;
  crtTableFilter: any;
  micTableFilter: any;
  followTableFilter: any;
  crtAdvancedFilter: any;
  micAdvancedFilter: any;
  followAdvancedFilter: any;
}

export const initialState: IFiltersState = {
  crtFilter: null,
  micFilter: null,
  followFilter: null,
  crtTableFilter: null,
  micTableFilter: null,
  followTableFilter: null,
  crtAdvancedFilter: null,
  micAdvancedFilter: null,
  followAdvancedFilter: null,
};

export const filtersReducer = createReducer(
  initialState,

  // ######### clear ALL [filtro, filtro tabela, filtro avançado]

  on(FiltersAction.clearAll, () => initialState),

  // ######### clear ALL [filtro]

  on(FiltersAction.clearAllFilters, (state) => ({
    ...state,
    followFilter: initialState.followFilter,
    crtFilter: initialState.crtFilter,
    micFilter: initialState.micFilter,
  })),

  // ######### clear ALL [filtro tabela]

  on(FiltersAction.clearAllTableFilters, (state) => ({
    ...state,
    followTableFilter: initialState.followTableFilter,
    crtTableFilter: initialState.crtTableFilter,
    micTableFilter: initialState.micTableFilter,
  })),

  // ######### clear ALL [filtro avançado]

  on(FiltersAction.clearAllAdvancedFilters, (state) => ({
    ...state,
    followAdvancedFilter: initialState.followAdvancedFilter,
    crtAdvancedFilter: initialState.crtAdvancedFilter,
    micAdvancedFilter: initialState.micAdvancedFilter,
  })),

  // ######### set, clear [filtro] (follow, crt, mic)

  on(FiltersAction.setFollowFilters, (state, { filters }) => ({
    ...state,
    followFilter: filters,
  })),

  on(FiltersAction.setCrtFilters, (state, { filters }) => ({
    ...state,
    crtFilter: filters,
  })),

  on(FiltersAction.setMicFilters, (state, { filters }) => ({
    ...state,
    micFilter: filters,
  })),

  on(FiltersAction.clearFollowFilters, (state) => ({
    ...state,
    followFilter: initialState.followFilter,
  })),

  on(FiltersAction.clearCrtFilters, (state) => ({
    ...state,
    crtFilter: initialState.crtFilter,
  })),

  on(FiltersAction.clearMicFilters, (state) => ({
    ...state,
    micFilter: initialState.micFilter,
  })),

  // ######### set, clear [filtro tabela] (follow, crt, mic)

  on(FiltersAction.setFollowTableFilters, (state, { form }) => ({
    ...state,
    followTableFilter: form,
  })),

  on(FiltersAction.setCrtTableFilters, (state, { form }) => ({
    ...state,
    crtTableFilter: form,
  })),

  on(FiltersAction.setMicTableFilters, (state, { form }) => ({
    ...state,
    micTableFilter: form,
  })),

  on(FiltersAction.clearFollowTableFilters, (state) => ({
    ...state,
    followTableFilter: initialState.followTableFilter,
  })),

  on(FiltersAction.clearCrtTableFilters, (state) => ({
    ...state,
    crtTableFilter: initialState.crtTableFilter,
  })),

  on(FiltersAction.clearMicTableFilters, (state) => ({
    ...state,
    micTableFilter: initialState.micTableFilter,
  })),

  // ######### set, clear [filtro avançado] (follow, crt, mic)

  on(FiltersAction.setFollowAdvancedFilters, (state, { form }) => ({
    ...state,
    followAdvancedFilter: form,
  })),

  on(FiltersAction.setCrtAdvancedFilters, (state, { form }) => ({
    ...state,
    crtAdvancedFilter: form,
  })),

  on(FiltersAction.setMicAdvancedFilters, (state, { form }) => ({
    ...state,
    micAdvancedFilter: form,
  })),

  on(FiltersAction.clearFollowAdvancedFilters, (state) => ({
    ...state,
    followAdvancedFilter: initialState.followAdvancedFilter,
  })),

  on(FiltersAction.clearCrtAdvancedFilters, (state) => ({
    ...state,
    crtAdvancedFilter: initialState.crtAdvancedFilter,
  })),

  on(FiltersAction.clearMicAdvancedFilters, (state) => ({
    ...state,
    micAdvancedFilter: initialState.micAdvancedFilter,
  })),

  // ######### update [filtro] (follow, crt, mic)

  on(
    FiltersAction.updateFollowFilterPagination,
    (state, { size, page, sortField, sortOrder }) => ({
      ...state,
      followFilter: {
        ...state.followFilter,
        size: size ?? state.followFilter?.size ?? 0,
        page: page ?? state.followFilter?.page ?? 0,
        sortField: sortField ?? state.followFilter?.sortField ?? '',
        sortOrder: sortOrder ?? state.followFilter?.sortOrder ?? '',
      } as DashboardProcessFilter,
    })
  ),

  on(
    FiltersAction.updateCrtFilterPagination,
    (state, { size, page, sortField, sortOrder }) => ({
      ...state,
      crtFilter: {
        ...state.crtFilter,
        size: size ?? state.crtFilter?.size ?? 0,
        page: page ?? state.crtFilter?.page ?? 0,
        sortField: sortField ?? state.crtFilter?.sortField ?? '',
        sortOrder: sortOrder ?? state.crtFilter?.sortOrder ?? '',
      } as CrtFilter,
    })
  ),

  on(
    FiltersAction.updateMicFilterPagination,
    (state, { size, page, sortField, sortOrder }) => ({
      ...state,
      micFilter: {
        ...state.micFilter,
        size: size ?? state.micFilter?.size ?? 0,
        page: page ?? state.micFilter?.page ?? 0,
        sortField: sortField ?? state.micFilter?.sortField ?? '',
        sortOrder: sortOrder ?? state.micFilter?.sortOrder ?? '',
      } as MicFilter,
    })
  )
);
