import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'template-message-low',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './template-message-low.component.html',
  styleUrls: ['./template-message-low.component.scss'],
})
export class TemplateMessageLowComponent implements OnInit {
  @Input({ required: true }) textMessage: string = '';
  @Input({ required: true }) severity: string = '';

  @Input() hasWrite: boolean = true;
  @Input() actionText: string = '';
  @Output() action = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * @description Método para emitir o evento de ação
   */
  emitAction() {
    this.action.emit();
  }
}
