<p class="p-small message-{{ severity }}">
  {{ textMessage }}
  <a
    *ngIf="actionText && hasWrite"
    class="p-small-bold message-{{ severity }} edit-link-{{ severity }}"
    (click)="emitAction()"
  >
    {{ actionText }}.</a
  >
</p>
