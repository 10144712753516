import { ElementRef, Injectable } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalComponentService {
  private closeAllModalsSubject = new BehaviorSubject<boolean>(false);
  closeAllModals$ = this.closeAllModalsSubject.asObservable();
  private dynamicDialogs: Set<DynamicDialogRef> = new Set();

  constructor() {}

  /**
   * @description Seleciona o select (preferência para o primeiro input do formulário) e adiciona o focus ao abrir o modal. OBS: Não funciona para ng-select, precisa ser inputs normais
   * @param {string} formControlName Nome do formControl do primeiro input válido do formulário
   * @param {ElementRef} elementRef ElementRef injetado do componente
   */
  setFocus(formControlName: string, elementRef: ElementRef) {
    setTimeout(() => {
      this._setFocusActions(formControlName, elementRef);
    }, 900);
  }

  private _setFocusActions(formControlName: string, elementRef: ElementRef) {
    const element = elementRef.nativeElement.querySelector(
      `[formcontrolname='${formControlName}']`
    );
    if (element) {
      element.focus();
    }
  }

  closeAllModals() {
    this.closeAllModalsSubject.next(true);
    this.dynamicDialogs.forEach((dialog) => dialog.close());
    this.dynamicDialogs.clear(); // Limpa a lista após fechar
  }

  registerDynamicDialog(ref: DynamicDialogRef) {
    this.dynamicDialogs.add(ref);
  }
}
